import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick';
import Test1 from '../../assets/images/yorum1.jpg'
import Test2 from '../../assets/images/yorum2.jpg'
import Test3 from '../../assets/images/yorum3.jpg'

const Test = {
    loop: true,
    items: 1,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 1500,
    autoplayTimeout: 5000
}

function Testimonial() {
    return (
        <>
            <section className="row_inner_am testimonial_section bg_white_textcher">
                <div className="container-fluid">
                    <div className="section_title text-center" data-aos="fade-up" data-aos-duration={1500} >
                        <span className="sub_text">Lezzetli sözler</span>
                        <h2>Musterİ degerlendirmelerİ</h2>
                    </div>
                    <div className="testimonial_wraper" data-aos="fade-in" data-aos-duration={1500} >
                        <Slider className="" id="testimonial_slider" arrows={false} autoplay={true} autoplaySpeed={2000}>
                            <div className="item">
                                <div className="testimonial_inner">
                                    <div className="img">
                                        <img src={Test1} alt="img" />
                                    </div>
                                    <div className="text">
                                        <span className="quote">“</span>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                        <h2>
                                        Klasik Payidar Tavuk Döner Dürüm denedim ve gerçekten çok beğendim! Hem doyurucu hem de inanılmaz lezzetliydi. Herkese tavsiye ederim.                                        </h2>
                                        <h3>
                                        Elif T.,{" "}
                                            <span className="designation"></span>
                                        </h3>
                                        <span className="quote text-right d-block">”</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_inner">
                                    <div className="img">
                                        <img src={Test2} alt="img" />
                                    </div>
                                    <div className="text">
                                        <span className="quote">“</span>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                        <h2>
                                        Acılı Payidar Tavuk Döner Dürüm harikaydı! Lezzet ve kalite mükemmel. Tekrar geleceğim.                                        </h2>
                                        <h3>
                                        Mehmet A.,{" "}
                                            <span className="designation"></span>
                                        </h3>
                                        <span className="quote text-right d-block">”</span>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial_inner">
                                    <div className="img">
                                        <img src={Test3} alt="img" />
                                    </div>
                                    <div className="text">
                                        <span className="quote">“</span>
                                        <div className="star">
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                            <span>
                                                <i className="icofont-star" />
                                            </span>
                                        </div>
                                        <h2>
                                        Cheddarlı Payidar Tavuk Döner Dürüm'ü çok sevdim. Arkadaşlarla burada harika bir akşam geçirdik.                                        </h2>
                                        <h3>
                                        Ahmet K.{" "}
                                            <span className="designation"></span>
                                        </h3>
                                        <span className="quote text-right d-block">”</span>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="text-center" data-aos="fade-in" data-aos-duration={1500}>
                        <Link to="/contactUs" className="btn btn_primary yellow_btn">
                            İletişime Geçin
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial