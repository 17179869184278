import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function Offermenu() {

    const [tabMenu, tabActive] = useState({ a: true });

    return (
        <>
            <section className="row_am offer_menu_section">
                <div className="container">
                    <div className="section_title text-center" data-aos="fade-up" data-aos-duration="1500">
                        <span className="sub_text">Lezzetlerimizi Deneyin</span>
                        <h2>Restoran Menü</h2>
                    </div>
                    <br /><br />
                  
                    <div className="tab-content" id="myTabContent" data-aos="fade-up" data-aos-duration="1500">
                        <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="lunch" role="tabpanel" aria-labelledby="lunch-tab">
                            <div className="menulist_wraper">
                                <div className="menulist left_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Soğuk İçecekler</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Coca-Cola Zero Sugar</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(33 Cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Coca-Cola </h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(33 cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Fanta</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(33 cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Spritte</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(33 cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Cappy</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(33 cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Sütaş Ayran</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(29,5 cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Sütaş Ayran</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(17,5 cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Su</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(50 cl.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Coca-Cola</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(1 L.)</span>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Coca-Cola</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">(2,5 L.)</span>
                                                </div>

                                            </li>
                                       
                                        </ul>
                                    </div>
                                
                                </div>
                                <div className="menulist right_side">
                                    <div className="menulist_box">
                                        <h3 className="menusub_text">Döner Menü</h3>
                                        <ul>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Klasik Payidar Tavuk Döner Dürüm</h4>
                                                        <span className="new">Lezzet</span>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>90 gr. tavuk döner, Hatay usulü sos, sarımsaklı mayonez, patates kızartması</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Acılı Payidar Tavuk Döner Dürüm </h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>90 gr. tavuk döner, Hatay usulü sos, acılı sarımsaklı mayonez, patates kızartması</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Doruk Payidar Tavuk Döner Dürüm</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>90 gr. tavuk döner, Hatay usulü sos, sarımsaklı mayonez, patates kızartması, karamelize soğan</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Kebap Payidar Tavuk Döner Dürüm</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>90 gr. tavuk döner, Hatay usulü sos, sarımsaklı mayonez, domates, turşu, maydanoz
                                                    </p>
                                                </div>
                                            </li>
                                             <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Delux Payidar Tavuk Döner Dürüm</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>90 gr. tavuk döner, Hatay usulü sos, sarımsaklı mayonez, cheddar peyniri, kaşar peyniri, delux sos
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mainCource">
                                                    <div className="dishName">
                                                        <h4>Cheddarlı Payidar Tavuk Döner Dürüm</h4>
                                                    </div>
                                                    <div className="doted"></div>
                                                    <span className="price">-</span>
                                                </div>
                                                <div className="description">
                                                    <p>Patates, mayonez, özel hatay sos, cheddar peyniri
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                               
                                </div>
                            </div>

                        </div>
                
                    </div>
                </div>
            </section>
        </>
    )
}

export default Offermenu